export const russiaRegions = [
  {
    fullname: 'Республика Адыгея',
    code: '01',
    name: 'Адыгея',
    typeShort: 'Респ',
    name_en: 'Republic of Adygea',
    federal: 'YFO',
  },
  {
    fullname: 'Республика Башкортостан',
    code: '02',
    name: 'Башкортостан',
    typeShort: 'Респ',
    name_en: 'Republic of Bashkortostan',
    federal: 'PFO',
  },
  {
    fullname: 'Республика Бурятия',
    code: '03',
    name: 'Бурятия',
    typeShort: 'Респ',
    name_en: 'Republic of Buryatia',
    federal: 'SFO',
  },
  {
    fullname: 'Республика Алтай',
    code: '04',
    name: 'Алтай',
    typeShort: 'Респ',
    name_en: 'Altai Republic',
    federal: 'SFO',
  },
  {
    fullname: 'Республика Дагестан',
    code: '05',
    name: 'Дагестан',
    typeShort: 'Респ',
    name_en: 'Dagestan',
    federal: 'SKFO',
  },
  {
    fullname: 'Республика Ингушетия',
    code: '06',
    name: 'Ингушетия',
    typeShort: 'Респ',
    name_en: 'Ingushetia',
    federal: 'SKFO',
  },
  {
    fullname: 'Кабардино-Балкарская Республика',
    code: '07',
    name: 'Кабардино-Балкарская',
    typeShort: 'Респ',
    name_en: 'Kabardino-Balkarian Republic',
    federal: 'SKFO',
  },
  {
    fullname: 'Республика Калмыкия',
    code: '08',
    name: 'Калмыкия',
    typeShort: 'Респ',
    name_en: 'Kalmykia',
    federal: 'YFO',
  },
  {
    fullname: 'Карачаево-Черкесская Республика',
    code: '09',
    name: 'Карачаево-Черкесская',
    typeShort: 'Респ',
    name_en: 'Karachay-Cherkessia',
    federal: 'SKFO',
  },
  {
    fullname: 'Республика Карелия',
    code: '10',
    name: 'Карелия',
    typeShort: 'Респ',
    name_en: 'Karelia',
    federal: 'SZFO',
  },
  {
    fullname: 'Республика Коми',
    code: '11',
    name: 'Коми',
    typeShort: 'Респ',
    name_en: 'Komi Republic',
    federal: 'SZFO',
  },
  {
    fullname: 'Республика Марий Эл',
    code: '12',
    name: 'Марий Эл',
    typeShort: 'Респ',
    name_en: 'Mari El',
    federal: 'PFO',
  },
  {
    fullname: 'Республика Мордовия',
    code: '13',
    name: 'Мордовия',
    typeShort: 'Респ',
    name_en: 'Republic of Mordovia',
    federal: 'PFO',
  },
  {
    fullname: 'Республика Саха /Якутия/',
    code: '14',
    name: 'Саха /Якутия/',
    typeShort: 'Респ',
    name_en: 'Republic of Sakha (Yakutia)',
    federal: 'DFO',
  },
  {
    fullname: 'Республика Северная Осетия - Алания',
    code: '15',
    name: 'Северная Осетия - Алания',
    typeShort: 'Респ',
    name_en: 'Republic of North Ossetia–Alania',
    federal: 'SKFO',
  },
  {
    fullname: 'Республика Татарстан',
    code: '16',
    name: 'Татарстан',
    typeShort: 'Респ',
    name_en: 'Republic of Tatarstan',
    federal: 'PFO',
  },
  {
    fullname: 'Республика Тыва',
    code: '17',
    name: 'Тыва',
    typeShort: 'Респ',
    name_en: 'Tuva Republic',
    federal: 'SFO',
  },
  {
    fullname: 'Удмуртская Республика',
    code: '18',
    name: 'Удмуртская',
    typeShort: 'Респ',
    name_en: 'Udmurtia',
    federal: 'PFO',
  },
  {
    fullname: 'Республика Хакасия',
    code: '19',
    name: 'Хакасия',
    typeShort: 'Респ',
    name_en: 'Republic of Khakassia',
    federal: 'SFO',
  },
  {
    fullname: 'Чеченская Республика',
    code: '20',
    name: 'Чеченская',
    typeShort: 'Респ',
    name_en: 'Chechen Republic',
    federal: 'SKFO',
  },
  {
    fullname: 'Чувашская Республика',
    code: '21',
    name: 'Чувашская',
    typeShort: 'Респ',
    name_en: 'Chuvash Republic',
    federal: 'PFO',
  },
  {
    fullname: 'Алтайский край',
    code: '22',
    name: 'Алтайский',
    typeShort: 'край',
    name_en: 'Altai Krai',
    federal: 'SFO',
  },
  {
    fullname: 'Краснодарский край',
    code: '23',
    name: 'Краснодарский',
    typeShort: 'край',
    name_en: 'Krasnodar Krai',
    federal: 'YFO',
  },
  {
    fullname: 'Красноярский край',
    code: '24',
    name: 'Красноярский',
    typeShort: 'край',
    name_en: 'Krasnoyarsk Krai',
    federal: 'SFO',
  },
  {
    fullname: 'Приморский край',
    code: '25',
    name: 'Приморский',
    typeShort: 'край',
    name_en: 'Primorsky Krai',
    federal: 'DFO',
  },
  {
    fullname: 'Ставропольский край',
    code: '26',
    name: 'Ставропольский',
    typeShort: 'край',
    name_en: 'Stavropol Krai',
    federal: 'SKFO',
  },
  {
    fullname: 'Хабаровский край',
    code: '27',
    name: 'Хабаровский',
    typeShort: 'край',
    name_en: 'Khabarovsk Krai',
    federal: 'DFO',
  },
  {
    fullname: 'Амурская область',
    code: '28',
    name: 'Амурская',
    typeShort: 'обл',
    name_en: 'Amur Oblast',
    federal: 'DFO',
  },
  {
    fullname: 'Архангельская область',
    code: '29',
    name: 'Архангельская',
    typeShort: 'обл',
    name_en: 'Arkhangelsk Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Астраханская область',
    code: '30',
    name: 'Астраханская',
    typeShort: 'обл',
    name_en: 'Astrakhan Oblast',
    federal: 'YFO',
  },
  {
    fullname: 'Белгородская область',
    code: '31',
    name: 'Белгородская',
    typeShort: 'обл',
    name_en: 'Belgorod Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Брянская область',
    code: '32',
    name: 'Брянская',
    typeShort: 'обл',
    name_en: 'Bryansk Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Владимирская область',
    code: '33',
    name: 'Владимирская',
    typeShort: 'обл',
    name_en: 'Vladimir Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Волгоградская область',
    code: '34',
    name: 'Волгоградская',
    typeShort: 'обл',
    name_en: 'Volgograd Oblast',
    federal: 'YFO',
  },
  {
    fullname: 'Вологодская область',
    code: '35',
    name: 'Вологодская',
    typeShort: 'обл',
    name_en: 'Vologda Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Воронежская область',
    code: '36',
    name: 'Воронежская',
    typeShort: 'обл',
    name_en: 'Voronezh Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Ивановская область',
    code: '37',
    name: 'Ивановская',
    typeShort: 'обл',
    name_en: 'Ivanovo Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Иркутская область',
    code: '38',
    name: 'Иркутская',
    typeShort: 'обл',
    name_en: 'Irkutsk Oblast',
    federal: 'SFO',
  },
  {
    fullname: 'Калининградская область',
    code: '39',
    name: 'Калининградская',
    typeShort: 'обл',
    name_en: 'Kaliningrad Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Калужская область',
    code: '40',
    name: 'Калужская',
    typeShort: 'обл',
    name_en: 'Kaluga Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Камчатский край',
    code: '41',
    name: 'Камчатский',
    typeShort: 'край',
    name_en: 'Kamchatka Krai',
    federal: 'DFO',
  },
  {
    fullname: 'Кемеровская область',
    code: '42',
    name: 'Кемеровская',
    typeShort: 'обл',
    name_en: 'Kemerovo Oblast',
    federal: 'SFO',
  },
  {
    fullname: 'Кировская область',
    code: '43',
    name: 'Кировская',
    typeShort: 'обл',
    name_en: 'Kirov Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Костромская область',
    code: '44',
    name: 'Костромская',
    typeShort: 'обл',
    name_en: 'Kostroma Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Курганская область',
    code: '45',
    name: 'Курганская',
    typeShort: 'обл',
    name_en: 'Kurgan Oblast',
    federal: 'UFO',
  },
  {
    fullname: 'Курская область',
    code: '46',
    name: 'Курская',
    typeShort: 'обл',
    name_en: 'Kursk Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Ленинградская область',
    code: '47',
    name: 'Ленинградская',
    typeShort: 'обл',
    name_en: 'Leningrad Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Липецкая область',
    code: '48',
    name: 'Липецкая',
    typeShort: 'обл',
    name_en: 'Lipetsk Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Магаданская область',
    code: '49',
    name: 'Магаданская',
    typeShort: 'обл',
    name_en: 'Magadan Oblast',
    federal: 'DFO',
  },
  {
    fullname: 'Московская область',
    code: '50',
    name: 'Московская',
    typeShort: 'обл',
    name_en: 'Moscow Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Мурманская область',
    code: '51',
    name: 'Мурманская',
    typeShort: 'обл',
    name_en: 'Murmansk Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Нижегородская область',
    code: '52',
    name: 'Нижегородская',
    typeShort: 'обл',
    name_en: 'Nizhny Novgorod Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Новгородская область',
    code: '53',
    name: 'Новгородская',
    typeShort: 'обл',
    name_en: 'Novgorod Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Новосибирская область',
    code: '54',
    name: 'Новосибирская',
    typeShort: 'обл',
    name_en: 'Novosibirsk Oblast',
    federal: 'SFO',
  },
  {
    fullname: 'Омская область',
    code: '55',
    name: 'Омская',
    typeShort: 'обл',
    name_en: 'Omsk Oblast',
    federal: 'SFO',
  },
  {
    fullname: 'Оренбургская область',
    code: '56',
    name: 'Оренбургская',
    typeShort: 'обл',
    name_en: 'Orenburg Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Орловская область',
    code: '57',
    name: 'Орловская',
    typeShort: 'обл',
    name_en: 'Oryol Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Пензенская область',
    code: '58',
    name: 'Пензенская',
    typeShort: 'обл',
    name_en: 'Penza Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Пермский край',
    code: '59',
    name: 'Пермский',
    typeShort: 'край',
    name_en: 'Perm Krai',
    federal: 'PFO',
  },
  {
    fullname: 'Псковская область',
    code: '60',
    name: 'Псковская',
    typeShort: 'обл',
    name_en: 'Pskov Oblast',
    federal: 'SZFO',
  },
  {
    fullname: 'Ростовская область',
    code: '61',
    name: 'Ростовская',
    typeShort: 'обл',
    name_en: 'Rostov Oblast',
    federal: 'YFO',
  },
  {
    fullname: 'Рязанская область',
    code: '62',
    name: 'Рязанская',
    typeShort: 'обл',
    name_en: 'Ryazan Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Самарская область',
    code: '63',
    name: 'Самарская',
    typeShort: 'обл',
    name_en: 'Samara Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Саратовская область',
    code: '64',
    name: 'Саратовская',
    typeShort: 'обл',
    name_en: 'Saratov Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Сахалинская область',
    code: '65',
    name: 'Сахалинская',
    typeShort: 'обл',
    name_en: 'Sakhalin Oblast',
    federal: 'DFO',
  },
  {
    fullname: 'Свердловская область',
    code: '66',
    name: 'Свердловская',
    typeShort: 'обл',
    name_en: 'Sverdlovsk Oblast',
    federal: 'UFO',
  },
  {
    fullname: 'Смоленская область',
    code: '67',
    name: 'Смоленская',
    typeShort: 'обл',
    name_en: 'Smolensk Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Тамбовская область',
    code: '68',
    name: 'Тамбовская',
    typeShort: 'обл',
    name_en: 'Tambov Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Тверская область',
    code: '69',
    name: 'Тверская',
    typeShort: 'обл',
    name_en: 'Tver Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Томская область',
    code: '70',
    name: 'Томская',
    typeShort: 'обл',
    name_en: 'Tomsk Oblast',
    federal: 'SFO',
  },
  {
    fullname: 'Тульская область',
    code: '71',
    name: 'Тульская',
    typeShort: 'обл',
    name_en: 'Tula Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Тюменская область',
    code: '72',
    name: 'Тюменская',
    typeShort: 'обл',
    name_en: 'Tyumen Oblast',
    federal: 'UFO',
  },
  {
    fullname: 'Ульяновская область',
    code: '73',
    name: 'Ульяновская',
    typeShort: 'обл',
    name_en: 'Ulyanovsk Oblast',
    federal: 'PFO',
  },
  {
    fullname: 'Челябинская область',
    code: '74',
    name: 'Челябинская',
    typeShort: 'обл',
    name_en: 'Chelyabinsk Oblast',
    federal: 'UFO',
  },
  {
    fullname: 'Забайкальский край',
    code: '75',
    name: 'Забайкальский',
    typeShort: 'край',
    name_en: 'Zabaykalsky Krai',
    federal: 'SFO',
  },
  {
    fullname: 'Ярославская область',
    code: '76',
    name: 'Ярославская',
    typeShort: 'обл',
    name_en: 'Yaroslavl Oblast',
    federal: 'CFO',
  },
  {
    fullname: 'Москва',
    code: '77',
    name: 'Москва',
    typeShort: 'г',
    name_en: 'Moscow',
    federal: 'CFO',
  },
  {
    fullname: 'Санкт-Петербург',
    code: '78',
    name: 'Санкт-Петербург',
    typeShort: 'г',
    name_en: 'Saint Petersburg',
    federal: 'SZFO',
  },
  {
    fullname: 'Еврейская автономная область',
    code: '79',
    name: 'Еврейская',
    typeShort: 'Аобл',
    name_en: 'Jewish Autonomous Oblast',
    federal: 'DFO',
  },
  {
    fullname: 'Ханты-Мансийский Автономный округ - Югра',
    code: '81',
    name: 'Ханты-Мансийский',
    typeShort: 'АО',
    name_en: 'Khanty-Mansi Autonomous Okrug',
    federal: 'UFO',
  },
  {
    fullname: 'Ненецкий автономный округ',
    code: '83',
    name: 'Ненецкий',
    typeShort: 'АО',
    name_en: 'Nenets Autonomous Okrug',
    federal: 'SZFO',
  },
  {
    fullname: 'Чукотский автономный округ',
    code: '87',
    name: 'Чукотский',
    typeShort: 'АО',
    name_en: 'Chukotka Autonomous Okrug',
    federal: 'DFO',
  },
  {
    fullname: 'Ямало-Ненецкий автономный округ',
    code: '89',
    name: 'Ямало-Ненецкий',
    typeShort: 'АО',
    name_en: 'Yamalo-Nenets Autonomous Okrug',
    federal: 'UFO',
  },
];

export const getSortedRegions = () => {
  return russiaRegions.sort((reg_a, reg_b) => {
    return reg_a.fullname.toString().localeCompare(reg_b.fullname.toString());
  });
};

export const getRegionCode = (regionName) => {
  const region = russiaRegions.find((region) => region.fullname === regionName);
  if (!region) return null;

  return region.code;
};

export const getRegionFederal = (regionName) => {
  const region = russiaRegions.find((region) => region.fullname === regionName);
  if (!region) return null;

  return region.federal;
};
