<template>
  <div style="display: flex; align-items: center; justify-content: center">
    <svg
      v-if="gender === 'Ж'"
      xmlns="http://www.w3.org/2000/svg"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      viewBox="0 -12 200 200"
    >
      <g>
        <path
          class="fil0"
          d="M 44 150 c -12.13 1.01 -21.77 11.49 -30 17 c 5.15 7.69 26.98 19.42 36 23 c 15.27 6.07 29.86 9.39 44.41 10 l 9.86 0 c 14.81 -0.62 29.74 -4.06 45.45 -10.28 c 10.44 -4.14 30.98 -15.8 36.28 -23.72 c -4.96 -7.41 -20.69 -14.86 -27 -15 c 0 -11.46 4 -18 4 -27 c 1 -11 -5.06 -25.94 -7.43 -36.57 c -2.89 -13 -3.79 -25.22 -6.01 -38.99 c -0.53 -3.32 -0.8 -7.01 -1.41 -9.59 c -0.93 -3.9 -8.97 -18.56 -11.97 -21.03 c -18.4 -15.1 -17.82 -17.51 -28.66 -17.82 l -12.71 0 l -0.81 0 c -17.36 0 -19.89 8.45 -25.25 13.75 c -4.3 4.26 -11.69 19.22 -13.65 25.35 c -4.08 12.76 -2.24 13.68 -3.68 23.32 c -1.2 8.02 0.47 17.21 -0.95 25.05 c -2.09 11.52 -4.06 12.86 -7.07 19.93 l -2.44 6.56 c -1.68 4.5 -3.06 8.45 -1.96 20.04 l 3 17 z"
        />
      </g>
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      style="shape-rendering: geometricPrecision; text-rendering: geometricPrecision; image-rendering: optimizeQuality; fill-rule: evenodd; clip-rule: evenodd"
      viewBox="0 -12 200 200"
    >
      <g>
        <path
          class="fil0"
          d="M52.05 69c0,7.33 1.85,10.78 2.84,17.16 1.05,6.84 1.48,9.67 9.16,9.84 0,19.81 18.11,31.13 -2.67,41.34l-33.62 14.37c-21.23,8.45 -19.18,8.67 -5.02,20.6 18.38,15.5 44.95,27.67 71.25,27.69l0.17 0c33.36,-0.02 57.77,-7.1 82.2,-27.69 3.6,-3.04 10.38,-7.41 11.69,-12.31 -2.45,-3.34 -11.72,-6.08 -16.03,-7.97l-33.86 -14.15c-14.95,-5.92 -10.56,-16.65 -5.75,-26.52 2.55,-5.25 2.64,-8.04 2.64,-15.36 10.1,-0.84 8.19,-5.39 10.05,-13.94 1.36,-6.2 6.21,-13.85 -2.05,-16.06 2.48,-10.66 8.87,-31.72 -2,-39 0.68,-2.57 5.57,-5.48 -6.43,-12.57 -2,-1.18 -2.66,-1.03 -4.15,-1.86 -4.87,-2.71 -0.46,-0.35 -3.17,-2.85 -4.67,-4.32 -5.37,-0.46 -4.25,-5.72l-7.98 1.17c1.12,-4.97 0.66,-1.72 -0.02,-3.17 -2.08,0 -5.24,0.43 -6.59,0.43 -3.78,0.01 -2.76,-1.6 -3.14,-2.43l-4.08 0c-1.06,0.26 -2.38,0.64 -4.05,1.16 -1.37,0.42 -1.1,0.37 -2.88,1.18 -6.78,3.08 -2.17,-0.66 -8.27,1.59 -3.27,1.2 -5.63,4.2 -9.85,6.22l-10.84 4.87c-2.4,2.9 2.33,0.62 -1.55,3.65 -1.99,1.56 -2.5,1.37 -4.56,3.53 -10.66,11.16 -5.73,31.35 -5.29,39.99 0.32,6.5 -1.9,2.72 -1.9,6.81z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'athletePhotoFiller-icon',
  props: {
    gender: {
      type: String,
      default: () => 'М',
    },
  },
};
</script>

<style scoped>
svg {
  fill: #757589;
  border-radius: 50%;
}
</style>
