<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      fill="currentColor"
      d="M2.25 1C1.56 1 1 1.56 1 2.25v1.162a1.5 1.5 0 0 0 .772 1.31l2.876 1.599a3 3 0 1 0 2.704 0l2.877-1.598A1.5 1.5 0 0 0 11 3.412V2.25C11 1.56 10.44 1 9.75 1zM5 5.372V2h2v3.372l-1 .556zM8 9a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
    />
  </svg>
</template>

<script>
export default {
  name: 'medal-icon',
};
</script>

<style scoped></style>
